import React from "react";
import BackIcon from "../../../../assets/svg/BackIcon";
import { MdInfo, MdEmail } from "react-icons/md";
import { CgPhone } from "react-icons/cg";
import { RiWhatsappFill } from "react-icons/ri";
import { RiTelegramFill } from "react-icons/ri";
// import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import HomeContact from "../../../../assets/images/home-cont-icon.svg";
import PhoneContact from "../../../../assets/images/phone-cont-icon.svg";
import EmailContact from "../../../../assets/images/email-cont-icon.svg";
import ContactImg from "../../../../assets/images/3D-illustration/contact-us-3.png";
import { useSelector } from "react-redux";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

const ContactUsBefore = () => {
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <>
      <Header />

      <main className="main contact_sec" id="payment_page">
        <div className=" container">
          <div className="d-flex justify-content-between mb-2">
            <h2 className="sectionTitle mb-0">Contact Us</h2>
            <a className="back-link" href="/">
              Back <BackIcon />
            </a>
          </div>

          <Row className="align-items-center justify-content-center">
            <Col lg={10} xl={8}>
              <div className="contact_info">
                <Row className="align-items-center">
                  <Col md={6}>
                    <h3 className="heading mb-4">Get In Touch With Us</h3>
                    <ul>
                      <li>
                        <a href={`tel://${appDetails?.PHONE}`}>
                          <div className="img_box">
                            <img src={PhoneContact} alt="Phone Icon" />
                          </div>
                          <h5>Phone</h5>
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col
                    md={6}
                    className="d-none d-md-flex justify-content-center"
                  >
                    <div className="contact_img">
                      <img src={ContactImg} alt="Contact Image" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </main>

      <BeforeLoginFooter />
    </>
  );
};

export default ContactUsBefore;
